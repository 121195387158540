import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
// disabled koska: https://buefy.org/documentation/customization
//import 'buefy/dist/buefy.css'

import App from './App.vue'

Vue.use(VueRouter)
Vue.use(Buefy)

import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false

// 1. Define route components.
// These can be imported from other files
import UtmValidator from './components/UtmValidator';
import QrCodeGenerator from './components/QrCodeGenerator';
import Home from './components/Home';
import NotFound from './components/404.vue';
import Trends from './components/Trends.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { 
    path: '/', 
    component: Home,
    meta: {
      title: 'Insights and Analytics for Digital Marketer',
      metaDesc: 'Digiguru Analytics presents you 100% free digital marketing tools and insights.',
      canonicalUrl: 'https://www.digiguru.xyz',
    }
  },
  { 
    path: '/utm-validator', 
    component: UtmValidator,
    props: { validatorType: 'web' },
    meta: {
      title: 'UTM Validator: Verify your campaign urls for Google Analytics',
      metaDesc: 'Validate your campaign URL easily for successful source tracking on Google Analytics. Digiguru.xyz offers you handy Internet marketing tools 100% free.',
      canonicalUrl: 'https://www.digiguru.xyz/utm-validator',
    }
  },
  { 
    path: '/utm-validator-android', 
    component: UtmValidator,
    props: { validatorType: 'android' },
    meta: {
      title: 'Android UTM Validator: Verify your campaign urls for Google Play Store app campaigns',
      metaDesc: 'Validate your app download campaign URLs easily for successful source tracking on Google Analytics. Digiguru.xyz offers you handy Internet marketing tools 100% free.',
      canonicalUrl: 'https://www.digiguru.xyz/utm-validator-android',
    }
  },
  { 
    path: '/qr-code-generator', 
    component: QrCodeGenerator,
    meta: {
      title: 'Easy QR Code Generator for Google Analytics Marketing Campaigns',
      metaDesc: 'Quickly generate and download QR codes for your online campaigns with this super-simple free tool. Digiguru.xyz offers you handy Internet marketing tools 100% free.',
      canonicalUrl: 'https://www.digiguru.xyz/qr-code-generator',
    }
  },
  { 
    path: '/country/:country', 
    component: Trends,
    props: true,
    meta: {
      title: 'Most popular Google searches',
      metaDesc: 'What people are searching for right now? We reveal the hottest searches and explain them with images and words. ',
      // canonicalUrl: 'https://www.digiguru.xyz/country/:country',
    }
  },
  { 
    path: '/privacy-policy', 
    component: PrivacyPolicy,
    meta: {
      title: 'Insights and Analytics for Digital Marketer',
      metaDesc: 'Privacy Policy',
      canonicalUrl: 'https://www.digiguru.xyz/privacy-policy',
    }
  },
  { 
    path: '*', 
    component: NotFound,
    meta: {
      title: '404 Page Not Found',
      metaDesc: 'Digiguru Analytics presents you 100% free digital marketing tools and insights.',
    }
  },
]

// title & meta näkymiin



// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes, // short for `routes: routes`,
  scrollBehavior () {
    return { x: 0, y: 0 } // disable scroll behavior
  }
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Digiguru.xyz';
    document.head.querySelector('meta[name=description]').content = to.meta.metaDesc    
  }
  if (to.meta.canonicalUrl) {
    document.head.querySelector('link[rel=canonical]').href = to.meta.canonicalUrl
  }
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
