<template>
<div id="home">
    <section class="section">
    <div class="container has-text-left">

        <h1 class="is-size-4">Free Campaign URL Validator Tools For Savvy Internet Marketers</h1>
         <p>
         To be able to track your campaign sources correctly on <a href="https://analytics.google.com/analytics/web/">Google Analytics</a>, the syntax for UTM campaign url must be correct. </p>
         <p>Take advantage of our popular validator tools to make sure you are sending valid data and attributing correct sources for your campaigns.  We offer validator for usual <router-link to="/utm-validator">web UTM campaigns</router-link> and also <router-link to="/utm-validator">Android app download campaigns for Google Play Store</router-link>. 
         </p>
         <br/>
         <h2>Which type of campaign URL you need to validate?</h2>
         <br/>
         <div class="columns">
  <div class="column is-one-third has-text-centered">
    <router-link to="/utm-validator"><b-icon icon="web" type="is-link" size="is-large" style="margin: 0 0.5rem 0 0;"></b-icon>
  <br><strong class="has-text-black">Google Analytics (Web)</strong><br>UTM Validator for web campaigns</router-link> »
  </div>
  <div class="column has-text-centered">
    <router-link to="/utm-validator"><b-icon icon="android" type="is-success" size="is-large" style="margin: 0 0.5rem 0 0;"></b-icon>
  <br><strong class="has-text-black">Google Analytics (Android)</strong><br>UTM Validator for Google Play Store app download campaigns</router-link> »
  </div>
</div>
         
  </div>

    </section>
    <section class="section">
    <ad-container />
  </section>
        </div>
</template>

<script>
import AdContainer from '@/components/AdContainer.vue'
export default {
  name: 'Home',
  components: {
      AdContainer,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
