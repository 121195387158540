<template>
  <div id="qr-code">
    <qrcode-vue :value="value" :size="size" level="H"></qrcode-vue>
  </div>
</template>
<script>
  import QrcodeVue from 'qrcode.vue'
 
  export default {
    data() {
      return {
        size: '180',
      }
    },
    props: {
        value: String,
    },
    components: {
      QrcodeVue,
    },
  }
</script> 