<template>
  <div id="qr-code-generator">
    <section class="section"> 
      <div class="container has-text-left">
      <h1>Create QR code for Google Analytics campaign tracking</h1>
      <p>
        This QR code generator offers a simple way to share campaign links for mobile users. User can access the link by scanning the QR code with built-in camera app or a dedicated QR code scanner app. This tool also validates your URL and you can download the generated QR for online or offline usage. Remember first to add <router-link to="/utm-validator">UTM tags</router-link> to your campaign link if you want to track campaign traffic and conversions on Google Analytics.
      </p>
      <br/>
      <div v-if="valiData.url && $v.valiData.url.minLength">
        <qr-code :value="valiData.url" :size="200"/>
      </div>
<form @submit.prevent="handleSubmit">
  <strong>How-to-use:</strong> Enter your campaign URL below and QR code will be automatically generated <img src="@/assets/curved_arrow_down.png" style="height: 1.5rem;" class="show-for-small-up&quot;" alt="">
    <b-input maxlength="1000" type="textarea" v-model.trim="valiData.url" @focus="clearStatus" @keypress="clearStatus" placeholder="Your campaign URL goes here" autofocus=""></b-input>
    <div class="error-message" v-if="!$v.valiData.url.url">Your URL is invalid. It should have <b>domain, full path and begin with http:// or https://</b>. No spaces.</div>
        <div class="error-message" v-if="!$v.valiData.url.minLength">Your URL must have at least {{$v.valiData.url.$params.minLength.min}} letters.</div>
        <div v-if="error" class="error-message">
        ❗Please check your URL
        </div>
        <a id="qr-download" download="qr-code-from-digiguru-xyz.png" v-if="valiData.url && $v.valiData.url.minLength && $v.valiData.url.url">
        <b-button type="is-link" v-on:click="download">Download QR Code &raquo;</b-button>
        </a>
</form>
      </div>
  </section>
  <section class="section"> 
  <div class="container has-text-left">
<b-icon icon="information-outline" size="is-medium" style="float: left; margin: 0 0.5rem 0 0;"></b-icon>
<h2>Want to validate your URL for valid Google Analytics tracking?</h2>
<br/>
<router-link to="/utm-validator"><b-icon icon="web" type="is-link" size="is-small" style="margin: 0 0.5rem 0 0;"></b-icon>
UTM Validator for web campaigns</router-link> »
<br/><br/>
    <router-link to="/utm-validator"><b-icon icon="android" type="is-success" size="is-small" style="margin: 0 0.5rem 0 0;"></b-icon>
UTM Validator for Google Play Store app download campaigns</router-link> »
<br><br>
<ad-container />

</div>
  </section>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, url } from 'vuelidate/lib/validators'
import AdContainer from '@/components/AdContainer.vue'
import QrCode from '@/components/QrCode.vue'



  export default {
    name: 'qr-code-generator',
    components: {
      AdContainer,
      QrCode,
    },
    props: {
        fixParameters: Object,
        validParameters: Object,
        msg: String,
        validatorType: String,
    },
    data() {
      return {
        submitting: false,
        error: false,
        success: false,
        valiData: {
          url: '',
        },
      }
    },
    mixins: [validationMixin],
    validations: {
        valiData: {
            url: {
            required,
            minLength: minLength(4),
            url,
            }
        },
    },
    methods: {
        handleSubmit() {

            this.$v.$touch()
            if (this.$v.$invalid) {
                this.error = true
            } else { 
            
            this.error = false;
            this.success = true;
            window.dataLayer.push(
        {
          'event': 'eventInfo',
          'eventCategory': 'qr_generator',
          'eventAction': 'qr url valid'
        }
        );
            }
            },
        clearStatus() {
            this.success = false
            this.error = false
        },
        status(validation) {
        return {
            error: validation.$error,
            dirty: validation.$dirty
        }
        },
        download() {
      var download = document.getElementById("qr-download");
      var image = document.querySelector("#qr-code canvas").toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
      download.setAttribute("href", image);
      window.dataLayer.push(
        {
          'event': 'eventInfo',
          'eventCategory': 'qr_generator',
          'eventAction': 'qr download'
        }
        )
      },
    },
    computed: {
        invalidUrl() {
            return this.valiData.url === ''
        },
    },
  }
</script>

<style scoped>
  .error-message {
    color: #d33c40;
    padding: 0 0 1.5rem 0;
  }

  .success-message {
    color: #32a95d;
  }

  .info-message {
    color: #000;
    padding: 0 0 1.5rem 0;
  }
</style>