<template>
  <div id="app">
    <navigation></navigation>
  <router-view></router-view>
  <br/>
  <footer class="footer">
  <div class="container">
  
    <nav class="level">
  <div class="level-item has-text-centered">
    <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                    src="@/assets/logo_digiguru_x3.png"
                    alt="Digiguru.xyz - Free Analytics Tools"
                    style="width: 15em;"
                >
            </b-navbar-item>
  </div>
  
       <div class="level-item has-text-centered">
     <router-link to="/utm-validator">
      UTM Validator Tool
     </router-link>
               
  </div>
      <div class="level-item has-text-centered">
    <router-link to="/utm-validator-android">
      Android UTM Validator Tool
  </router-link>
  </div>
    <div class="level-item has-text-centered">
  <router-link to="/qr-code-generator">
      QR Code Generator
  </router-link>
  </div>
  <div class="level-item has-text-centered">

              <router-link to="/country/us">
                Google Search Trends <span style="font-size:9px; padding-left: 0.2rem;">BETA</span>
            </router-link>
  </div>
  <div class="level-item has-text-centered">
    contact@digiguru.xyz

  </div>
    
      </nav>

      
  <div class="content">
    
    <div class="column is-size-7 has-text-left">
    <p>Digiguru Analytics presents you 100% free digital marketing tools. We are totally independent service without affiliation to any content presented.
All trademarks are the property of their respective owners. Trend rankings are based on public <a href="https://www.google.com" target="_blank" rel="noopener">Google</a> and <a href="https://www.twitter.com" target="_blank" rel="noopener">Twitter</a> data.
    </p>
    </div>
  </div>

 </div>
 </footer>
</div>
</template>

<script>
import Navigation from './components/Navigation.vue'
var data = new Object
export default {
  name: 'App',
  components: {
    Navigation,
  },
  data() {
    return data
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
a:hover {text-decoration: underline;}

h1 {
  font-size: 1.5rem !important;
  margin-bottom: 0.4rem !important;
}

h2 {
  font-size: 1.25rem !important;
  margin-top: 0.2rem !important;
  margin-bottom: 0.4rem !important;
}

h3,h4 {
  margin-top: 0.2rem !important;
  margin-bottom: 0.4rem !important;
}


#qr-code {
  margin: 1.0rem;
}

.countryFlagList a {
  text-transform: capitalize;
  font-size: 0.85rem;
}
</style>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Links
$link: $blue;
$link-visited: $blue;
$link-hover: $blue;

// Elementit
$section-padding: 1.5rem 1.5rem;

// Text
$body-line-height: 1.6;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>