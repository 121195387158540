<template>
  <div class="hello">
      <br/>
      <b-icon icon="cloud-off-outline" size="is-large">
            </b-icon>
            <br/>
    <h1>(404) Page Not Found</h1>
    <p>Feeling lost? Visit <router-link to="/">Digiguru Analytics homepage</router-link></p>
        <section class="section">
    <ad-container />
  </section>
  </div>
</template>

<script>
import AdContainer from '@/components/AdContainer.vue'
export default {
  name: '404',
  components: {
      AdContainer,
    },
}
</script>