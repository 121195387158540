<template>
  <div id="utm-validator">
    <section class="section"> 
    <web-utm-form @submit:validation="validateUrl" v-bind:fixParameters="fixParameters" v-bind:validParameters="validParameters" msg="" v-bind:validatorType="validatorType" />
  </section>
  <div class="container">
  <div class="notification has-text-left">
    
<b-icon icon="information-outline" size="is-medium" style="float: left; margin: 0 0.5rem 0 0;"></b-icon>
<h2>Recommended UTM parameters for every campaign</h2>
<br/>
<strong>utm_source</strong> (mandatory)
<br>
Use utm_source to identify a search engine, newsletter name, or other source.
Google Analytics requires you to have at least this parameter in your url to make campaign tracking work.
<em>Example: utm_source=google</em>
<br>
<br>


<strong>utm_medium</strong>
<br>
Use utm_medium to identify a medium such as email or cost-per-click.
<em>Example: utm_medium=cpc</em>
<br><br>

<strong>utm_campaign</strong>
<br>
Use utm_campaign to identify a specific product promotion or strategic campaign.
<em>Example: utm_campaign=spring_sale</em>
<br><br>
<b-icon icon="information-outline" size="is-medium" style="float: left; margin: 0 0.5rem 0 0;"></b-icon>
<h2>Optional UTM parameters for more detailed tracking</h2>
<br/>



<strong>utm_term</strong>
<br>
Used for paid search. Use utm_term to note the keywords for this ad.
<em>Example: utm_term=running+shoes</em>

 <br><br>
<strong>utm_content</strong>
<br>
Used for A/B testing and content-targeted ads. Use utm_content to differentiate ads or links that point to the same URL.
<em>Examples: utm_content=logolink or utm_content=textlink</em>
<br><br>
<strong>utm_id</strong>
<br>
Used to identify which ads campaign this referral references. Use utm_id to identify a specific ads campaign.
<em>Example: utm_id=abc123</em>
  </div>
  <section class="section">
<div class="container has-text-left" v-if="validatorType=='android'">
<h2>Need more help creating your Android app marketing URL?</h2>
» Use the great <a href="https://ga-dev-tools.google/ga4/campaign-url-builder/play/" target="_blank" rel="noopener">Google URL builder</a> to build your campaign URL and return here to validate it. 
<br/>
» Further information about campaign tagging best practices can be found at <a href="https://support.google.com/google-ads/answer/13823256?hl=en" target="_blank" rel="noopener">Google Analytics Android official support pages</a>.  
</div>
<div class="container has-text-left" v-else>
<h2>Need more help creating your GA marketing URL?</h2>
» Use the great <a href="https://ga-dev-tools.google/ga4/campaign-url-builder/" target="_blank" rel="noopener">Google URL builder</a> to build your campaign URL and return here to validate it. 
<br/>
» Further information about campaign tagging best practices can be found at <a href="https://support.google.com/analytics/?hl=en#topic=14090456" target="_blank" rel="noopener">Google Analytics official support pages</a>.  
</div>
<br>
<ad-container />
</section>
</div>
    </div>
</template>

<script>
import WebUtmForm from '@/components/WebUtmForm.vue'
import AdContainer from '@/components/AdContainer.vue'

export default {
  name: 'App',
  components: {
    WebUtmForm,
    AdContainer,
  },
  props: {
        validatorType: String,
  },
  methods: {
    validateUrl(valiData) {
      let self=this
      let validateAPI = 'https://api2.digiguru.xyz/api/v1/validate';
      // let validateAPI = 'http://127.0.0.1:5000//api/v1/validate';
      if (valiData.validatorType == 'android') {
        validateAPI = 'https://api2.digiguru.xyz/api/v1/validateAndroid';
        // validateAPI = 'http://127.0.0.1:5000//api/v1/validateAndroid';
      } 
      fetch(validateAPI, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({url:valiData.url})
      }).then((res) => res.json())
    .then(function(data) { 
      // console.log(data) 
      self.fixParameters = data.errors
      if (!self.fixParameters.syntaxErrorMsg) {
        self.validParameters = data.valid
        window.dataLayer.push({
          'event': 'eventInfo',
          'eventCategory': 'utm_validate_'+valiData.validatorType,
          'eventAction': 'success',
          });
      } else {
        self.validParameters = {}
         window.dataLayer.push({
          'event': 'eventInfo',
          'eventCategory': 'utm_validate_'+valiData.validatorType,
          'eventAction': 'failure',
          'eventLabel': self.fixParameters.syntaxErrorMsg
          });
      }
      })
    .catch((err)=>console.error(err));
    },
  },
  data() {
    return {
      fixParameters: {},
      validParameters: {},
    }
  },
   metaInfo: { 
    link: [
      { 
        rel: 'canonical',
        href: 'https://www.digiguru.xyz/utm-validator' },
    ]
  },
}
</script>