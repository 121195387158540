<template>
<div id="trends">
    <section class="section">
    <div class="container has-text-left">
      <img :src="require(`@/assets/flags/${country}.png`)" alt="" style="float:left; margin-right:1.0rem; height:2.5rem;">
    <h1 class="is-size-4">Hottest Google searches in <span style="text-transform: capitalize;">{{countryPretty}}</span></h1>
    <p>
           Most popular Google search terms and trending topics in <span style="text-transform: capitalize;">{{countryPretty}}</span> today. Rankings are based on <a href="https://trends.google.com/trends/" target="_blank">Google Trends</a> data.<br><span v-if="this.post">Last updated  {{ this.post[0].date }}</span>.
   </p>
   <br>
  <div class="columns is-mobile is-multiline">
      <div class="column is-half-mobile is-one-fifth-tablet" v-for="(value, key) in post" :key="key">
<div class="card">
  <div class="card-image">
    <figure class="image is-square">
      <img :src="value.imgUrl" :alt="value.trendname"/>
    </figure>
  </div>
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-6">
          <b-icon icon="arrow-up-bold" size="is-small" style="color: green;" v-if="value.dayrank_prev && value.dayrank < value.dayrank_prev"></b-icon>
          <b-icon icon="arrow-down-bold" size="is-small" style="color: red;" v-if="value.dayrank_prev && value.dayrank > value.dayrank_prev"></b-icon>
          {{ value.dayrank }}. {{ value.trendname }}
          </p>
      </div>
    </div>
  </div>
</div>
      </div>
  </div>

</div>
    </section>

  <section class="section">
    <div class="container has-text-left">
      <h2>Browse Google Search trends around the globe</h2>
    <div class="columns is-multiline countryFlagList has-text-centered is-mobile">
        <div class="column is-one-third-mobile is-2-tablet is-1-desktop" v-for="(value, key) in countries" :key="key">
          <router-link :to="'/country/'+value.countrycode"><img :src="require(`@/assets/flags/${value.countrycode}.png`)" alt="">
    <br>{{(value['prettyname']) ? value['prettyname'] : value['name']}}</router-link>
        </div>
    </div>
    </div>
  </section>
    <section class="section">
    <ad-container />
  </section>
</div>
</template>

<script>
import axios from "axios";
import AdContainer from '@/components/AdContainer.vue'
export default {
  name: 'Trends',
  components: {
      AdContainer,
  },
  data() {
    return {
      post: null,
      countries: null,
      countryPretty: ''
    };
  },
  created: function() {
    this.fetchTrends()
    
  },
  methods: {
    fetchTrends() {
      axios
      .get("https://api2.digiguru.xyz/api/v1/gtrends/latest?id="+this.country)
      // .get("http://127.0.0.1:5000/api/v1/gtrends/latest?id="+this.country)
      .then(res => {
        this.post = res.data;
      })
      axios
      .get("https://api2.digiguru.xyz/api/v1/gtrends/countries")
      .then(res => {
        this.countries = res.data;
        this.countryPretty = this.countries.find(({ countrycode }) => countrycode === this.country ).prettyname 
        if (!this.countryPretty) { 
          this.countryPretty = this.countries.find(({ countrycode }) => countrycode === this.country ).name 
        }
        document.title = 'Most popular Google searches in ' + this.countryPretty + ' | Digiguru.xyz'
        document.head.querySelector('link[rel=canonical]').href = 'https://www.digiguru.xyz/country/'+this.country
    })
    }, 
  },
  watch: {
    '$route': 'fetchTrends',
  },  
  props: {
      country: String,
  },
  /*
  computed: {
    countryPretty: function () {
      let prettyname = this.countries.find(({ countrycode }) => countrycode === this.country ).prettyname
      if (!prettyname) { 
        prettyname = this.countries.find(({ countrycode }) => countrycode === this.country ).name 
      }
      return prettyname
    }
  }
    */
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
