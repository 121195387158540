<template>
    <div class="container has-text-left">
  <div id="web-utm-form">
        <h1 v-if="validatorType=='web'">Check your URL validity for Google Analytics UTM campaign tracking</h1>
        <h1 v-else>Validate Android app download campaign URL for Google Analytics UTM tracking</h1>
    <p v-if="validatorType=='web'">
         UTM parameters offer powerful way to tag traffic sources for your marketing campaign, but Google Analytics is merciless for misspelled parameters in your URL.  Validate your URL with this free tool and never have broken UTM tracking links anymore. You can use this validator for both GA4 and Universal Analytics ("GA3") -versions of Google Analytics.
		</p>
  <p v-else>
         UTM parameters offer powerful way to track traffic sources for your Android app download campaigns for <a href="https://play.google.com/store">Google Play Store</a>, but GA is merciless for misspelled parameters in your URL.  Validate your Google Play Store campaign URL with this free tool and never have broken UTM tracking links anymore. You can use this validator for both GA4 and Universal Analytics ("GA3") -versions of Google Analytics.
	</p>
    <br/>
    <form @submit.prevent="handleSubmit">
                  <p><strong>How-to-use:</strong> Paste your campaign URL below and hit validate. <!--<b-icon icon="arrow-bottom-left" size="is-small">
            </b-icon>--><img src="@/assets/curved_arrow_down.png" style="height: 1.5rem;" class="show-for-small-up&quot;" alt=""></p>     
        <b-field>
          <b-input maxlength="1000" type="textarea" :class="{ 'has-error': error }" v-model.trim="valiData.url" @focus="clearStatus" @keypress="clearStatus" placeholder="Your campaign URL goes here" autofocus=""></b-input>
        <!--<textarea :class="{ 'has-error': error }" v-model.trim="valiData.url" @focus="clearStatus" @keypress="clearStatus" placeholder="Your campaign URL goes here" autofocus=""></textarea>-->
         </b-field>
        <!-- <div class="error-message" v-if="!$v.valiData.url.required">Please enter your URL</div> -->
        <div class="error-message" v-if="!$v.valiData.url.url">Your URL is invalid. It should have <b>domain, full path and begin with http:// or https://</b>. No spaces.</div>
        <div class="error-message" v-if="!$v.valiData.url.minLength">Your URL must have at least {{$v.valiData.url.$params.minLength.min}} letters.</div>
        <div class="error-message" v-if="!$v.valiData.url.utmParameters">You need to have <b>utm_source</b> parameter in your url.</div>
        <div v-if="error" class="error-message">
        ❗Please check your URL
        </div>
        <div v-if="fixParameters.syntaxErrorMsg" class="error-message">
        ❗Please fix your parameters: {{ fixParameters['syntaxErrorMsg'] }}
        </div>
        <div class="columns" v-if="(success && validParameters.source && !fixParameters.syntaxErrorMsg)">
        <div class="column success-message is-two-thirds">
          ✅ <b>Nice!</b> Your campaign url is OK
          <div class="info-message">
          <br/>
          <table>
          <tr><th colspan="2"><b>This is what you will be sending to Google Analytics:</b></th></tr>
          <tr v-if="validParameters.source"><td><b>Source:</b></td><td>{{validParameters.source}}</td></tr>
          <tr v-if="validParameters.medium"><td><b>Medium:</b></td><td>{{validParameters.medium}}</td></tr>
          <tr v-if="validParameters.campaign"><td><b>Campaign:</b></td><td>{{validParameters.campaign}}</td></tr>
          <tr v-if="validParameters.content"><td><b>Content:</b></td><td>{{validParameters.content}}</td></tr>
          <tr v-if="validParameters.term"><td><b>Term:</b></td><td>{{validParameters.term}}</td></tr>
          <tr v-if="validParameters.id"><td><b>Campaign ID:</b></td><td>{{validParameters.id}}</td></tr>
        </table>
          <br/>
          <div v-if="!validParameters.medium">Please notice! We recommend you include <b>utm_medium</b> parameter for more effective medium tracking</div>
          <div v-if="!validParameters.campaign">Please notice! We recommend you include <b>utm_campaign</b> parameter for more effective medium tracking</div>
        </div>
        </div>
        <div class="column"><ad-container /></div>
        </div>
        <section>
        <b-button type="is-link" native-type="submit">Validate &raquo;</b-button>
        <br/><br/>
        <div class="columns" v-if="(success && validParameters.source && !fixParameters.syntaxErrorMsg)">
        <div class="column is-two-thirds">
        <h2>Try out your campaign link on your own browser</h2>
        1. Current browser: <a :href="valiData.url" target="_blank" id="testYourLink">Open your campaign link in a new browser tab &raquo; </a>
        <br />
        2. Mobile device: 
        Scan QR code below with your camera app and open the link &raquo;
        <qr-code :value="valiData.url"/>
        </div>
        </div>
        </section>
    </form>
  </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, url, helpers } from 'vuelidate/lib/validators'
import AdContainer from '@/components/AdContainer.vue'
import QrCode from '@/components/QrCode.vue'
const utmParameters = (value) => !helpers.req(value) || value.indexOf('utm_source') >= 0

  export default {
    name: 'web-utm-form',
    components: {
      AdContainer,
      QrCode,
    },
    props: {
        fixParameters: Object,
        validParameters: Object,
        msg: String,
        validatorType: String,
    },
    data() {
      return {
        submitting: false,
        error: false,
        success: false,
        valiData: {
          url: '',
        },
      }
    },
    mixins: [validationMixin],
    validations: {
        valiData: {
            url: {
            required,
            minLength: minLength(4),
            url,
            utmParameters,
            }
        },
    },
    methods: {
        handleSubmit() {

            this.$v.$touch()
            if (this.$v.$invalid) {
                this.error = true
            } else { 
            this.valiData.validatorType = this.validatorType;
            this.$emit('submit:validation', this.valiData)
            this.error = false;
            this.success = true;
            }
            },
        clearStatus() {
            this.success = false
            this.error = false
        },
        status(validation) {
        return {
            error: validation.$error,
            dirty: validation.$dirty
        }
        },
    },
    computed: {
        invalidUrl() {
            return this.valiData.url === ''
        },
    },
  }
</script>

<style scoped>
  .error-message {
    color: #d33c40;
    padding: 0 0 1.5rem 0;
  }

  .success-message {
    color: #32a95d;
  }

  .info-message {
    color: #000;
    padding: 0 0 1.5rem 0;
  }
</style>